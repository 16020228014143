import PropTypes from "prop-types";
import React from "react";
import ScrollSpyHeaderContainer from "app/pages/.shared/ScrollSpyHeader/ScrollSpyHeaderContainer.jsx";
import "./pages/.styles/index.scss";
import HeadMetadata from "app/utils/metas/HeadMetadata";
import { brandPropTypes, strategyShape } from "app/utils/propTypes";
import { BRANDS, PRODUCTS_VISIBILITY } from "app/constants";
import { StickyContainer } from "react-sticky";
import { LoadingBar } from "app/pages/.shared/LoadingBar/LoadingBar";
import { matchPath, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { dispatchOnQueries, redirectOnMerch, redirectOnOperatorLogin } from "app/utils/routerUtils";
import HeaderContainer from "app/pages/Header/HeaderContainer";
import BookingHeaderContainer from "app/pages/Booking/BookingHeader/BookingHeaderContainer";
import FooterContainer from "app/pages/Footer/FooterContainer";
import HomeHeaderContainer from "app/pages/Home/HomeHeaderContainer";
import SimpleHeaderContainer from "app/pages/Header/SimpleHeader/SimpleHeaderContainer";
import JeuConcours from "@brand/JeuConcours/JeuConcours";
import Teasing from "@brand/Teasing/default/Teasing";
import ScrollToTop from "app/pages/.shared/Scroll/ScrollToTop";
import {
	AsyncAccountContainer,
	AsyncAuthContainer,
	AsyncBooking,
	AsyncBreakContainer,
	AsyncConfirmationContainer,
	AsyncFicheProduitContainer,
	AsyncHomeContainer,
	AsyncInsurance,
	AsyncLandingAuthContainer,
	AsyncLandingPageContainer,
	AsyncLandingProductContainer,
	AsyncListingContainer,
	AsyncMerchandisingContainer,
	AsyncMyBookings,
	AsyncNotFound,
	AsyncPaymentExternalRedirect,
	AsyncPriceMatchGuarantee,
	AsyncSearchBookingPage,
	AsyncUnsubscribeContainer,
} from "app/routes";
import OauthRoute from "app/pages/.shared/OauthRoute";
import BookWithConfidence from "app/pages/.shared/BookWithConfidence/BookWithConfidence";
import TechnicalErrorContainer from "app/pages/TechnicalError/TechnicalErrorContainer";
import AsidePanelContainer from "app/pages/.shared/AsidePanel/AsidePanelContainer";
import { AsideMenuAccountContainer } from "app/pages/.shared/AsideMenu/AsideMenuAccountContainer";

const PerfectApp = ({
	authLayer,
	brand,
	isWhiteLabel,
	shop,
	isAuthenticated,
	productsVisibility,
}) => {
	return (
		<ScrollToTop>
			<LoadingBar />

			<HeadMetadata page="default" />
			<AsidePanelContainer>
				<AsideMenuAccountContainer />
			</AsidePanelContainer>
			{authLayer}

			{brand === BRANDS.PS ? (
				<div>
					<Switch>
						<Route path="/account" component={SimpleHeaderContainer} />
						<Route path="/not-found" component={HeaderContainer} />
						<Route path="/technical-error" component={HeaderContainer} />
					</Switch>

					<Switch>
						<Route
							path="/account"
							render={props => {
								return <AsyncAccountContainer {...props} />;
							}}
						/>
						<Route path="/technical-error" component={TechnicalErrorContainer} />
						<Route
							exact
							path="/"
							render={props => {
								return <AsyncSearchBookingPage {...props} />;
							}}
						/>
						<Redirect from="/*" to="/" />
					</Switch>
				</div>
			) : (
				<StickyContainer>
					<Switch>
						<Route
							path="/home"
							render={() => {
								return (
									brand !== BRANDS.AF &&
									brand !== BRANDS.TO &&
									brand !== BRANDS.AX &&
									brand !== BRANDS.EK && <HomeHeaderContainer />
								);
							}}
						/>
						<Route path="/auth" component={null} />
						<Route path="/giveaway" component={null} />
						<Route path="/unsubscribe" component={HeaderContainer} />
						<Route path="/account" component={HeaderContainer} />
						<Route path="/landing-page" component={null} />
						<Route path="/contest" component={HeaderContainer} />
						<Route path="/schizophrene" component={HeaderContainer} />
						<Route path="/priceMatchGuarantee" component={HeaderContainer} />
						<Route path="/insurance" component={HeaderContainer} />
						<Route path="/booking" component={BookingHeaderContainer} />
						<Route path="/external-redirect" component={null} />
						<Route path="/confirmation" component={HeaderContainer} />
						<Route path="/not-found" component={HeaderContainer} />
						<Route path="/technical-error" component={SimpleHeaderContainer} />
						<Route path="/magazine" component={null} />
						<Route path="/lp" component={null} />
						<Route path="/listing">{brand !== BRANDS.TZ && <HeaderContainer />}</Route>
						<Route path="/merch" component={HeaderContainer} />
						<Route path="/teasing" component={null} />
						<Route path="/product/id/:productId" component={HeaderContainer} />
						<Route path="/product/:productUri" component={SimpleHeaderContainer} />
						<Route path="/break" component={HeaderContainer} />
						<Route path="/:productUri" component={HeaderContainer} />
						<Route component={HeaderContainer} />
					</Switch>

					<Switch>
						{isWhiteLabel && (
							<Route
								path="/home"
								render={props => {
									dispatchOnQueries(props);

									return <AsyncHomeContainer {...props} />;
								}}
							/>
						)}
						{isWhiteLabel && (
							<Route
								path="/auth"
								render={props => {
									return <AsyncAuthContainer {...props} />;
								}}
							/>
						)}
						{isWhiteLabel && (
							<Route
								path="/unsubscribe"
								render={props => {
									dispatchOnQueries(props);

									return <AsyncUnsubscribeContainer {...props} />;
								}}
							/>
						)}
						{(isWhiteLabel || isAuthenticated) && (
							<Route
								path="/account"
								render={props => {
									return <AsyncAccountContainer {...props} />;
								}}
							/>
						)}

						{isWhiteLabel && (
							<Route
								path="/landing-page/:operationCode"
								render={props => {
									dispatchOnQueries(props);

									return <AsyncLandingAuthContainer {...props} />;
								}}
							/>
						)}

						{isWhiteLabel && (
							<Route
								path="/giveaway"
								render={props => {
									if (shop !== "en-GB") {
										return <Redirect to={{ pathname: "/listing" }} />;
									}

									return <JeuConcours {...props} />;
								}}
							/>
						)}

						<Route
							path="/product/id/:productId"
							render={props => {
								// TODO remettre après coronavirus
								if (productsVisibility !== PRODUCTS_VISIBILITY.PREVIEW) {
									return <Redirect to={{ pathname: "/break" }} />;
								}
								dispatchOnQueries(props);
								return <AsyncFicheProduitContainer {...props} />;
							}}
						/>

						{isWhiteLabel && (
							<Route
								path="/product/:productUri"
								render={props => {
									dispatchOnQueries(props);

									return <AsyncLandingProductContainer {...props} />;
								}}
							/>
						)}

						{/* Routes communes */}
						<Route
							path="/schizophrene"
							render={props => {
								redirectOnOperatorLogin(props);
							}}
						/>
						<Route
							path="/priceMatchGuarantee"
							render={props => {
								return <AsyncPriceMatchGuarantee {...props} />;
							}}
						/>
						<Route
							path="/insurance"
							render={props => {
								return <AsyncInsurance {...props} />;
							}}
						/>
						<Route
							path="/booking"
							render={props => {
								return <AsyncBooking {...props} />;
							}}
						/>
						<Route
							path="/external-redirect"
							render={props => {
								return <AsyncPaymentExternalRedirect {...props} />;
							}}
						/>
						<Route
							path="/confirmation"
							render={props => {
								return <AsyncConfirmationContainer {...props} />;
							}}
						/>
						<Route
							path="/not-found"
							render={props => {
								return <AsyncNotFound {...props} />;
							}}
						/>
						<Route path="/technical-error" component={TechnicalErrorContainer} />

						{brand === BRANDS.LR && (
							<OauthRoute path="/mybookings" component={AsyncMyBookings} />
						)}

						<Route
							path="/lp"
							render={props => {
								return <AsyncLandingPageContainer {...props} />;
							}}
						/>
						<Route
							path="/listing"
							render={props => {
								// TODO remettre après coronavirus
								if (productsVisibility !== PRODUCTS_VISIBILITY.PREVIEW) {
									return <Redirect to={{ pathname: "/break" }} />;
								}
								dispatchOnQueries(props);
								return <AsyncListingContainer {...props} />;
							}}
						/>
						<Route
							path="/merch"
							render={props => {
								// TODO remettre après coronavirus
								if (productsVisibility !== PRODUCTS_VISIBILITY.PREVIEW) {
									return <Redirect to={{ pathname: "/break" }} />;
								}
								dispatchOnQueries(props);
								redirectOnMerch({ brand, ...props });

								return <AsyncMerchandisingContainer {...props} />;
							}}
						/>

						{isWhiteLabel && <Route path="/teasing" component={Teasing} />}

						<Route path="/break">
							<AsyncBreakContainer />
						</Route>

						<Route
							path="/:productUri"
							render={props => {
								// TODO remettre après coronavirus
								if (productsVisibility !== PRODUCTS_VISIBILITY.PREVIEW) {
									return <Redirect to={{ pathname: "/break" }} />;
								}
								dispatchOnQueries(props);
								return <AsyncFicheProduitContainer {...props} />;
							}}
						/>
					</Switch>
				</StickyContainer>
			)}

			<Route
				render={({ location }) =>
					!matchPath(location.pathname, {
						path: ["/external-redirect"] /** array of paths where we hide footer */,
					}) && (
						<React.Fragment>
							<BookWithConfidence shop={shop} />
							<FooterContainer />
						</React.Fragment>
					)
				}
			/>

			{brand !== BRANDS.DD && brand !== BRANDS.MS && <ScrollSpyHeaderContainer />}
		</ScrollToTop>
	);
};

PerfectApp.propTypes = {
	authLayer: PropTypes.element,
	brand: brandPropTypes,
	match: PropTypes.object,
	productsVisibility: PropTypes.string,
	shop: PropTypes.string,
	strategy: strategyShape,
	isWhiteLabel: PropTypes.bool,
	isAuthenticated: PropTypes.bool,
};

export default withRouter(PerfectApp);
