import { createSelector } from "reselect";
import { BRANDS, RESOLUTION } from "app/constants";
import sortBy from "lodash/sortBy";
import find from "lodash/find";
import get from "lodash/get";
import includes from "lodash/includes";
import { FIRST_MERCHANDISING_POSITION } from "src/@brand/appConstants";
import { meeroPhotosFilter } from "app/utils/abTestsUtils";
import { getABTestsStatus, getActiveVariationID } from "app/reducers/abTestsSelector";
import { zonedTimeToUtc } from "date-fns-tz";

const merchandisings = state => state.merchandising.list;
const getActiveFilter = state => state.merchandising.activeFilter;
const getCurrentMerchandising = state => state.merchandising.current;
const getProducts = state => state.products;
const getProductsOfMerchandising = state => state.merchandising.products;
const getResolution = state => state.resolution;
const getPartner = state => state.partner;
const getBrand = state => state.brand.code;
const getMerchPreviewStartDate = state => state.merchandising.previewStartDate;
const getSpotlightUri = (state, spotlightUri = null) => spotlightUri;

/**
 * On ne veut pas les merch PARRAINAGE si le système de parrainage n'est pas actif
 */
export const getVisibleMerchandisings = createSelector(
	[merchandisings, getPartner, getMerchPreviewStartDate],
	(merchandisings = [], partner = {}, previewStartDate) => {
		// previewStartDate est valorisé par la query param ?preview=YYYYMMDD
		const previewDateTimestamp = zonedTimeToUtc(previewStartDate, "YYYYMMDD");

		return merchandisings.filter(merchandising => {
			const merchEndAtTimestamp = new Date(merchandising.endAt).getTime();
			const merchStartAtTimestamp = new Date(merchandising.startAt).getTime();

			if (
				previewDateTimestamp &&
				(previewDateTimestamp >= merchEndAtTimestamp ||
					previewDateTimestamp < merchStartAtTimestamp)
			) {
				return false;
			}

			if (!merchandising.partnerCode || partner.code === merchandising.partnerCode) {
				if (merchandising.code === "PARRAINAGE") {
					return partner.enableSponsorship;
				}

				if (merchandising.code === "PAIEMENT4X") {
					return partner.code === "AFFR" || partner.code === "EKFR";
				}

				return true;
			}

			return false;
		});
	}
);

export const getMerchandisingByPosition = (position, idSpotlight) => {
	return createSelector(
		[getVisibleMerchandisings, getProducts, getResolution],
		(merchandisings = [], products, resolution) => {
			return {
				position,
				merchList: sortBy(
					merchandisings.filter(merch => {
						if (
							(resolution === RESOLUTION.LARGE ||
								resolution === RESOLUTION.XLARGE ||
								resolution === RESOLUTION.MEDIUM) &&
							(!merch.photoLarge ||
								!merch.photoLarge.url ||
								merch.photoLarge.url === "")
						) {
							return false;
						} else if (
							resolution === RESOLUTION.SMALL &&
							(!merch.photoSmall ||
								(!merch.photoSmall.url && merch.photoSmall.url === ""))
						) {
							return false;
						}

						const spotlightProduct = find(products, product => {
							return product.uri === idSpotlight;
						});

						if (spotlightProduct) {
							return merch.fallbackPosition === position;
						}
						return merch.position === position;
					}),
					"headerIndex"
				),
			};
		}
	);
};

export const getMerchandisingTopicsFilterValuesFromMerchCode = createSelector(
	[getCurrentMerchandising],
	(merchandising = {}) => {
		let merchandisingTopics = [];

		if (merchandising.merchandisingTopics) {
			merchandisingTopics = merchandising.merchandisingTopics.reduce(
				(filterValues, { filterValue: currentFilterValue }) => {
					return currentFilterValue
						? [...filterValues, currentFilterValue]
						: filterValues;
				},
				[]
			);
		}

		return merchandisingTopics;
	}
);

export const getMerchandisingRange0 = createSelector(
	[getVisibleMerchandisings, getProducts, getResolution, getSpotlightUri],
	(merchandisings = [], products = [], resolution, idSpotlight) => {
		return {
			position: FIRST_MERCHANDISING_POSITION,
			merchList: sortBy(
				merchandisings.filter(merch => {
					if (
						(resolution === RESOLUTION.LARGE ||
							resolution === RESOLUTION.XLARGE ||
							resolution === RESOLUTION.MEDIUM) &&
						(!merch.photoLarge || !merch.photoLarge.url || merch.photoLarge.url === "")
					) {
						return false;
					} else if (
						resolution === RESOLUTION.SMALL &&
						(!merch.photoSmall ||
							(!merch.photoSmall.url && merch.photoSmall.url === ""))
					) {
						return false;
					}

					const hasSpotlightProduct = products.some(product => {
						return product.uri === idSpotlight;
					});

					if (hasSpotlightProduct) {
						return merch.fallbackPosition === FIRST_MERCHANDISING_POSITION;
					}

					return merch.position === FIRST_MERCHANDISING_POSITION;
				}),
				"headerIndex"
			),
		};
	}
);

export const getListingHeaderMerchandisings = createSelector(
	[getMerchandisingRange0, getBrand],
	(merchandisings, brand) => {
		if (brand === BRANDS.EK && get(merchandisings, "merchList.length") >= 3) {
			const minimumSlidesListLength = 6;
			let doubleMerchandisingList = merchandisings.merchList;
			if (merchandisings.merchList.length) {
				const maxIteration = minimumSlidesListLength / merchandisings.merchList.length || 1;
				for (let factor = 1; factor < maxIteration; factor++) {
					doubleMerchandisingList = [
						...doubleMerchandisingList,
						...merchandisings.merchList,
					];
				}
				return { ...merchandisings, merchList: doubleMerchandisingList };
			}
		}
		return merchandisings;
	}
);

export const getFilteredProductsOfMerchandising = createSelector(
	[getProductsOfMerchandising, getActiveFilter],
	(products = [], activeFilter) => {
		if (activeFilter) {
			return products.filter(product => {
				return includes(product.merchandisingTopics, activeFilter);
			});
		}

		return products;
	}
);

export const getVisibleProductsOfMerchandising = createSelector(
	[getFilteredProductsOfMerchandising, getABTestsStatus, getActiveVariationID],
	(products = [], abTestsStatus, activeVariationID) => {
		// TODO : Remove after ABTest
		return products.map(product => {
			const photos = meeroPhotosFilter(product.photos, abTestsStatus, activeVariationID); // canary listing variation ID

			return { ...product, photos };
		});
	}
);

export const getMerchandisingMedias = createSelector(
	[getCurrentMerchandising],
	(merchandising = {}) => {
		const medias = get(merchandising, "media.articles");
		return medias;
	}
);
