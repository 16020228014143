import loadable from "@loadable/component";

export const AsyncListingContainer = loadable(() =>
	import(/* webpackChunkName: "listing" */ "./pages/Listing/ListingContainer")
);

export const AsyncPriceMatchGuarantee = loadable(() =>
	import(/* webpackChunkName: "best-match-guarantee" */ "./pages/BestMatchGuarantee/BestMatchGuarantee")
);

export const AsyncFicheProduitContainer = loadable(() =>
	import(/* webpackChunkName: "fiche-produit" */ "./pages/FicheProduit/containers/FicheProduitContainer")
);

export const AsyncMerchandisingContainer = loadable(() =>
	import(/* webpackChunkName: "merchandising" */ "./pages/Merchandising/MerchandisingContainer")
);

export const AsyncLandingPageContainer = loadable(() =>
	import(/* webpackChunkName: "landing-page" */ "./pages/LandingPage/LandingPageContainer")
);

export const AsyncConfirmationContainer = loadable(() =>
	import(/* webpackChunkName: "confirmation" */ "./pages/Confirmation/ConfirmationContainer")
);

export const AsyncLandingProductContainer = loadable(() =>
	import(/* webpackChunkName: "landing-product" */ "./pages/LandingProduct/LandingProductContainer")
);

export const AsyncLandingAuthContainer = loadable(() =>
	import(/* webpackChunkName: "landing-auth" */ "./pages/LandingPage/Auth/LandingAuthContainer")
);

export const AsyncAccountContainer = loadable(() =>
	import(/* webpackChunkName: "account" */ "./pages/Account/AccountContainer")
);

export const AsyncUnsubscribeContainer = loadable(() =>
	import(/* webpackChunkName: "unsubscribe" */ "./pages/Unsubscribe/UnsubscribeContainer")
);

export const AsyncAuthContainer = loadable(() =>
	import(/* webpackChunkName: "auth" */ "./pages/Auth/AuthPage/AuthPageContainer")
);

export const AsyncHomeContainer = loadable(() =>
	import(/* webpackChunkName: "home" */ "./pages/Home/HomeContainer")
);

export const AsyncSearchBookingPage = loadable(() =>
	import(/* webpackChunkName: "search-booking" */ "./pages/CorporateSite/CorporateSiteContainer.jsx")
);

export const AsyncNotFound = loadable(() =>
	import(/* webpackChunkName: "not-found" */ "./pages/NotFound/NotFound")
);

export const AsyncPaymentExternalRedirect = loadable(() =>
	import(/* webpackChunkName: "payment-external-redirect" */ "./pages/Booking/ExternalRedirect/PaymentExternalRedirect")
);

export const AsyncBooking = loadable(() =>
	import(/* webpackChunkName: "booking" */ "./pages/Booking/Booking")
);

export const AsyncInsurance = loadable(() =>
	import(/* webpackChunkName: "insurances" */ "./pages/Insurance/Insurance")
);

export const AsyncProfileContainer = loadable(() =>
	import(/* webpackChunkName: "profile" */ "./pages/Account/Profile/ProfileContainer")
);

export const AsyncMyBookingsContainer = loadable(() =>
	import(/* webpackChunkName: "my-bookings" */ "./pages/Account/MyBookings/MyBookingsContainer")
);

export const AsyncSubscriptionsContainer = loadable(() =>
	import(/* webpackChunkName: "subscriptions" */ "./pages/Account/Subscriptions/SubscriptionsContainer")
);

export const AsyncSecurityContainer = loadable(() =>
	import(/* webpackChunkName: "security" */ "./pages/Account/Security/SecurityContainer")
);

export const AsyncParrainageContainer = loadable(() =>
	import(/* webpackChunkName: "parrainage" */ "./pages/Account/Parrainage/ParrainageContainer")
);

export const AsyncMyCreditsContainer = loadable(() =>
	import(/* webpackChunkName: "my-credits" */ "./pages/Account/MyCredits/MyCreditsContainer")
);

export const AsyncBookingAsideContainer = loadable(() =>
	import(/* webpackChunkName: "booking-aside" */ "./pages/Booking/BookingAsideContainer")
);

export const AsyncRentalSearchEngineContainer = loadable(() =>
	import(/* webpackChunkName: "rental-serach-engine" */ "./pages/PrePackage/rental/RentalSearchEngineContainer")
);

export const AsyncSearchEngineContainer = loadable(() =>
	import(/* webpackChunkName: "search-engine" */ "./pages/PrePackage/containers/SearchEngineContainer")
);

export const AsyncQuotationContainer = loadable(() =>
	import(/* webpackChunkName: "quotation" */ "./pages/Booking/Quotation/QuotationContainer")
);

export const AsyncBookingSearchContainer = loadable(() =>
	import(/* webpackChunkName: "search" */ "./pages/Booking/Search/BookingSearchContainer")
);

export const AsyncAuthBookingContainer = loadable(() =>
	import(/* webpackChunkName: "auth-booking" */ "./pages/Booking/Auth/AuthBookingContainer")
);

export const AsyncLandingMarketing = loadable(() =>
	import(/* webpackChunkName: "landing-marketing" */ "./pages/LandingPage/Auth/LandingMarketing/LandingMarketing")
);

export const AsyncMyBookings = loadable(() =>
	import(/* webpackChunkName: "my-bookings" */ "./pages/MyBookings/MyBookings")
);

export const AsyncBreakContainer = loadable(() =>
	import(/* webpackChunkName: "break" */ "./pages/Break/BreakContainer")
);

// export const AsyncPaymentContainer = loadable(() =>
// 	import("./pages/Booking/Payment/PaymentContainer")
// );
