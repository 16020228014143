import { connect } from "react-redux";
import Reassurance from "app/pages/Footer/Reassurance/Reassurance";
import { BRANDS } from "app/constants";

const mapStateToProps = state => {
	const countryCode = state.shop.slice(3, 5);
	const partnerCode = state.partner.code;
	const brandCode = state.brand.code;

	return {
		showPayment4x: countryCode === "FR" && brandCode !== BRANDS.AX,
		showPaypal: countryCode !== "CH" && partnerCode !== "PSVP" && brandCode !== BRANDS.AX,
		showCB: brandCode !== BRANDS.AX,
	};
};

export default connect(mapStateToProps)(Reassurance);
