import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { isWhiteLabel } from "app/reducers/brandSelector";
import TechnicalError from "@brand/TechnicalError/TechnicalError";
import { getBrandDisplayName } from "app/reducers/partnerSelector";
import { useLocation } from "react-router-dom";

const TechnicalErrorContainer = props => {
	const location = useLocation();

	const errorMessage = get(location, "state.errorMessage");

	return <TechnicalError {...props} errorMessage={errorMessage} />;
};

const mapStateToProps = state => ({
	strategy: state.partner.strategy,
	brandDisplayName: getBrandDisplayName(state),
	shop: state.shop,
	isWhiteLabel: isWhiteLabel(state),
	isDoubleOptin: state.partner.isDoubleOptin,
	teasingEndDate: state.partner.teasingEndDate,
});

export default connect(mapStateToProps)(TechnicalErrorContainer);
