import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Page from "app/pages/.shared/Page/Page";
import { Route, Switch } from "react-router-dom";
import TechnicalErrorRegistrationExpired from "./registration/expired/TechnicalErrorRegistrationExpired";
import TechnicalErrorRegistrationErrorDefault from "./registration/default/TechnicalErrorRegistrationErrorDefault";
import TechnicalErrorDefault from "./default/TechnicalErrorDefault";
import TechnicalErrorSSO from "./sso/TechnicalErrorSSO";
import { STRATEGY } from "app/constants";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { strategyShape } from "app/utils/propTypes";
import TechnicalErrorDuplicateBooking from "app/pages/TechnicalError/duplicateBooking/TechnicalErrorDuplicateBooking";

import "./technicalError.scss";

const TechnicalError = ({
	match,
	strategy,
	isDoubleOptin,
	teasingEndDate,
	isWhiteLabel,
	shop,
	brandDisplayName,
	errorMessage,
}) => (
	<div className="technical-errors">
		<Switch>
			{isDoubleOptin && (
				<Route
					path={`${match.url}/registration-confirmation-expired`}
					render={props => {
						return (
							<Page title={<FormattedMessage id="technical.error.title" />}>
								<TechnicalErrorRegistrationExpired
									{...props}
									teasingEndDate={teasingEndDate}
								/>
							</Page>
						);
					}}
				/>
			)}
			{isDoubleOptin && (
				<Route
					path={`${match.url}/confirm-registration-failed`}
					render={props => {
						return (
							<Page title={<FormattedMessage id="technical.error.title" />}>
								<TechnicalErrorRegistrationErrorDefault {...props} />
							</Page>
						);
					}}
				/>
			)}
			{strategy === STRATEGY.OAUTH && (
				<Route
					path={`${match.url}/sso`}
					render={props => {
						return (
							<Page title={<FormattedMessage id="technical.sso.error.title" />}>
								<TechnicalErrorSSO {...props} />
							</Page>
						);
					}}
				/>
			)}
			<Route
				path={`${match.url}/duplicate-booking`}
				exact
				render={props => {
					const existingBooking = get(props, "location.state.existingBooking");

					return (
						<Page
							title={
								<FormattedMessage id="technical.error.duplicate.booking.title" />
							}
						>
							{!isEmpty(existingBooking) ? (
								<TechnicalErrorDuplicateBooking
									{...props}
									bookingReference={existingBooking.reference}
									email={existingBooking.email}
									endDate={existingBooking.stayTo}
									startDate={existingBooking.stayFrom}
									isWhiteLabel={isWhiteLabel}
									shop={shop}
								/>
							) : (
								<TechnicalErrorDefault
									{...props}
									errorMessage={errorMessage}
									brandDisplayName={brandDisplayName}
								/>
							)}
						</Page>
					);
				}}
			/>
			<Route
				path={`${match.url}/`}
				exact
				render={props => {
					return (
						<Page title={<FormattedMessage id="technical.error.title" />}>
							<TechnicalErrorDefault
								{...props}
								errorMessage={errorMessage}
								brandDisplayName={brandDisplayName}
							/>
						</Page>
					);
				}}
			/>
		</Switch>
	</div>
);

TechnicalError.defaultProps = {
	match: {},
	errorMessage: "",
};

TechnicalError.propTypes = {
	match: PropTypes.object,
	isDoubleOptin: PropTypes.bool,
	brandDisplayName: PropTypes.string,
	teasingEndDate: PropTypes.string,
	isWhiteLabel: PropTypes.bool,
	shop: PropTypes.string,
	errorMessage: PropTypes.string,
	strategy: strategyShape,
};

export default TechnicalError;
